#warningPopup {

  .warning-container {
    display: flex;
    flex-direction: column;
    padding: 24px;

    .popup-actions {
      display: flex;
      flex-direction: row;
      margin-top: 48px;

      .button {
        flex: 1;
        margin: 0 32px;
      }
    }
  }
}
