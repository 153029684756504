@import "../../../assets/colors";

.button {
  padding: 18px 10px 16px 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .1s ease-in background-color;

  span {
    font-size: 14px;
    line-height: 16px;
    color: $white;
  }

  &.orange {
    background-color: $flamingo;

    &:hover {
      background-color: lighten($flamingo, 10);
    }
  }

  &.green {
    background-color: $moutainMeadow;

    &:hover {
      background-color: lighten($moutainMeadow, 10);
    }
  }

  &.blue {
    background-color: $bondiBlue;

    &:hover {
      background-color: lighten($bondiBlue, 10);
    }
  }

  &.grey {
    background-color: $wildSand;

    &:hover {
      background-color: darken($wildSand, 10);
    }

    span {
      color: $cerulean;
    }

    i {
      color: $treePoppy;
    }
  }

  i {
    margin-left: auto;
    color: $white
  }

  &.disabled {
    background-color: $bombay;
    &:hover {
      background-color: $bombay;
    }
    cursor: not-allowed;
  }
}
