$alto: #D1D1D1;
$armadillo: #433C35;
$athensGray: #EFF2F4; //inputText
$black: #000000;
$black20: rgba(0,0,0,0.2);
$bombay: #B1B2BA; //icon
$bondiBlue: #00A0AF;
$bondiBlue10: rgba(0, 160, 175, 0.1);
$bondiBlue20: rgba(0, 160, 175, 0.2);
$bondiBlue30: rgba(0, 160, 175, 0.3);
$cerulean: #0093E0; //blue
$codGray: #1E1E1E; //text
$flamingo: #F16122;
$hummingBird: #CDEDF8;
$lynch: #688197; //label
$mercury: #E4E4E4;
$moutainMeadow: #1DB46C;
$rollingStone: #748285;
$scarlet: #EA1806;
$treePoppy: #F7941E; //orange
$white: #FFFFFF;
$whiteIce: #EBF8FC;
$wildSand: #F5F5F5;
$barleyWhite: #FFF0CF;
$white70: rgba(255, 255, 255, 0.7);
$white30: rgba(255, 255, 255, 0.3);
$saffron: #f2b843;
