@import "../../../../../../assets/colors";

.user-info {

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      flex: 1;
      color: $armadillo;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
    }
  }

  #add-edit {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    >p {
      flex: 1;
      margin-right: 24px;
      font-size: 14px;
      color: $scarlet;
    }

    .edit-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      p {
        font-size: 14px;
        color: $rollingStone;
      }

      i {
        margin: 12px;
        color: $rollingStone;
      }

      &:hover {
        p, i {
          color: $moutainMeadow;
        }
      }

      &.disabled {
        cursor: not-allowed;

        p, i {
          color: $alto;
        }

        &:hover {
          p, i {
            color: lighten($alto, 10);
          }
        }
      }
    }

    .delete-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      p {
        font-size: 14px;
        color: $rollingStone;
      }

      i {
        margin: 12px;
        color: $rollingStone;
      }

      &:hover {
        p, i {
          color: $scarlet;
        }
      }

      &.disabled {
        cursor: not-allowed;

        p, i {
          color: $alto;
        }

        &:hover {
          p, i {
            color: lighten($alto, 10);
          }
        }
      }
    }
  }
}
