@import "../../../../assets/colors";

#formSchool {
  background-color: $white;

  .not-allowed {
    cursor: not-allowed;
  }

  > .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .form-wrapper {
      width: 570px;
      padding-top: 58px;
      padding-bottom: 48px;

      .rne-container {
        .input-wrapper {
          margin-top: 16px;
          margin-bottom: 24px;
        }

        .search {
          margin-bottom: 32px;
          margin-left: 15%;
          margin-right: 15%;
        }

        .skip {
          margin-top: 32px;
          margin-left: 15%;
          margin-right: 15%;
        }
      }

      .school-container {

        .change-rne-container {

          .button {
            margin-top: 16px;
          }
        }
      }

      .school-container,
      .summary-container {

        .title-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 32px;
          margin-bottom: 24px;

          .title {
            flex: 1;
          }

          .edit-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;

            p {
              font-size: 14px;
              color: $rollingStone;
            }

            i {
              margin: 12px;
              color: $rollingStone;
            }

            &:hover {
              p, i {
                color: $moutainMeadow;
              }
            }
          }
        }

        .wrapper {
          display: flex;
          flex-direction: row;
          margin: 16px 0;

          .input-wrapper {
            margin: 0 16px;

            &:first-of-type {
              margin-left: 0;
            }

            &:last-of-type {
              margin-right: 0;
            }

            &.country, &.phone, &.phone, &.email {
              flex: 1;
            }

            &.address, &.firstName {
              flex: 47;
            }

            &.postal-code, &.civility {
              flex: 15;
            }

            &.city, &.lastName {
              flex: 27;
            }
          }

          .clean-drop-down {
            margin: 0 16px;

            &:first-of-type {
              margin-left: 0;
            }

            &:last-of-type {
              margin-right: 0;
            }

            &.region, &.academy {
              flex: 1;
            }

            &.civility {
              flex: 15;
            }
          }
        }
      }

      .projects-container {
        .list-wrapper {

          .section {
            .info {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-top: 30px;

              .title {
                color: $armadillo;
                font-size: 18px;
                font-weight: bold;
                line-height: 24px;
              }

              .type {
                flex: 1;
              }

              .delete-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;

                p {
                  font-size: 14px;
                  color: $rollingStone;
                }

                i {
                  margin: 12px;
                  color: $rollingStone;
                }

                &:hover {
                  p, i {
                    color: $scarlet;
                  }
                }
              }

            }

            > .add-supervisor {
              margin-top: 24px;
            }
          }

          .bottom-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 24px;

            .error {
              margin-left: 64px;

              p {
                color: $scarlet;
                font-size: 12px;
                text-align: center;
              }
            }
          }

          .button {
            width: 200px;
            padding: 10px 18px;
          }
        }
      }

      .summary-container {
        .projects-info {

          .project {

            .info {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-bottom: 32px;

              .title {
                color: $armadillo;
                font-size: 18px;
                font-weight: bold;
                line-height: 24px;
              }

              .type {
                flex: 1;
              }

              i {
                margin: 12px;
                color: $rollingStone;
                cursor: pointer;
              }
            }

            > .add-supervisor {
              margin-top: 24px;
            }

            .supervisors {

              hr {
                margin: 0;
              }

              .supervisor {
                display: flex;
                flex-direction: row;
                margin: 6px 0;

                .info-container {
                  flex: 1;

                  .info-label {
                    color: $lynch;
                    font-size: 12px;
                    line-height: 16px;
                  }

                  .info-value {
                    color: $armadillo;
                    font-size: 13px;
                    line-height: 24px;
                  }
                }
              }
            }

            hr {
              margin-top: 0;
            }
          }
        }
      }

      .school-container > .title,
      .rne-container > .title,
      .projects-container > .title,
      .summary-container > .title {
        color: $codGray;
        font-size: 32px;
        font-weight: bold;
        line-height: 32px;
        margin-bottom: 38px;
      }

      .school-container > .section .title {
        color: $armadillo;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
      }

      .summary-container > .section .title {
        color: $armadillo;
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
      }
    }

    hr {
      border-top: 1px dotted $alto;
      border-left: none;
      border-right: none;
      border-bottom: none;
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
}

@media screen and (max-width: 800px) {
  #formSchool {

    > .container {

      > .form-wrapper {
        width: 100%;
        padding: 24px;
        box-sizing: border-box;

        .projects-container {
          .list-wrapper {
            .section {
              .info {
                flex-direction: column;
                align-items: self-start;

                .type {
                  margin: 16px 0;

                  .radio-button {
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
        .school-container .wrapper,
        .projects-container .wrapper,
        .summary-container .wrapper {
          flex-direction: column;
          margin: 0;

          .input-wrapper {
            margin: 4px 0;
          }

          .clean-drop-down {
            margin: 4px 0;
          }
        }
      }
    }
  }
}
