@import "../../../../../assets/colors";

#sideNavigation {
  position: absolute;
  top: 32px;
  left: 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  .actions {
    display: flex;
    flex-direction: column;

    .button {
      height: auto;
      padding: 8px 16px;
      margin: 6px;
    }
  }

  .step-navigation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6px;
    margin-top: 12px;
    box-sizing: border-box;

    .vl {
      width: 2px;
      height: 40px;
      background-color: $alto;
    }

    i {
      padding: 4px;
      font-size: 36px;
      color: $treePoppy;
      background-color: $wildSand;
      border-radius: 24px;
      cursor: pointer;

      &:hover {
        background-color: darken($wildSand, 10);
      }

      &.disabled {
        background-color: $whiteIce;
        color: #748285;
        cursor: not-allowed;

        &:hover {
          background-color: $whiteIce;
        }
      }
    }
  }

  &.sticky {
    position: fixed;
    top: calc(95px + 32px);
  }
}

@media screen and (max-width: 1160px) {
  #sideNavigation {
    left: 2%;
  }
}

@media screen and (max-width: 960px) {
  #sideNavigation {
    width: 100%;
    position: relative;
    top: auto;
    left: auto;
    padding: 8px 32px;
    background-color: $white;
    z-index: 99;

    .actions {
      flex-direction: row;
    }

    .step-navigation {
      width: auto;
      justify-content: space-between;

      i {
        margin-left: 64px;
        margin-right: 64px;
      }
    }

    &.sticky {
      top: 0;
    }
  }
}
