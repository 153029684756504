#creationPopup {

  .form-wrapper {
    max-width: 570px;
    min-width: 280px;
    padding: 80px;

    .identity {
      display: flex;
      flex-direction: row;

      .input-wrapper {
        flex: 1;
        margin-left: 16px;
        margin-right: 16px;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .email {
      margin-top: 12px;
    }

    .cgu {
      margin: 15px 0;
      font-size: 12px;
      color: #688197;
      font-family: "Ubuntu", sans-serif;
    }

    .button {
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 800px) {
  #creationPopup {

    .wrapper {
      width: 100%;

      .form-wrapper {
        padding: 40px 0;
      }
    }
  }
}
