@import "../../../assets/colors";
@import "../../../assets/dimens";

#navigation {
  display: flex;
  width: 100%;
  height: 95px;
  overflow: hidden;

  #fixed-wrapper {
    width: calc(100% - 2 * 32px);
    height: calc(95px - 2 * 12px);
    display: flex;
    position: fixed;
    padding: 12px 32px;
    background-color: $white;
    z-index: 9;
    box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15), 0 8px 26px 0 rgba(0, 0, 0, 0.15);
    justify-content: space-between;

    .main-logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      max-height: 100%;

      &:hover {
        background-color: rgba(62, 157, 216, 0.33);
      }

      >img {
        width: auto;
        max-width: 100%;
        height: 100%;
      }
    }

    >img {
      height: calc(100% - 20px);
      margin: 10px 24px;
    }

    >.items {
      display: flex;
      align-items: center;
      justify-items: center;
      margin: auto;
      color: $white;

      li {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        margin: 0 8px;
        color: $bondiBlue;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          color: $codGray;
        }

        &.is-current {
          cursor: initial;
          color: $codGray;
        }
      }
    }

    .identification {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $bondiBlue;
      cursor: pointer;

      &:hover {
        color: $codGray;
      }

      >span {
        margin-right: 20px;

        @include width(800px) {
          display: none
        }
      }

      >.material-icons {
        font-size: 35px;


      }
    }

    #menu {
      display: none;

      >i {
        width: 72px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        color: $codGray;
        transition: .2s ease-in background-color;
        cursor: pointer;

        &.menu {
          left: 0;
          top: 0;
        }
      }

      .wrapper {
        width: 320px;
        height: 100%;
        position: fixed;
        top: 95px;
        left: -320px;
        opacity: 0;
        background-color: $white;
        color: $white;
        transition: .3s ease-in all;

        &.displayed {
          left: 0;
          opacity: 1;
        }

        >.items {
          margin-left: auto;
          display: flex;
          flex-direction: column;
          color: white;

          li {
            font-size: 18px;
            padding: 0 28px;
            height: 60px;
            display: flex;
            align-items: center;
            color: $bondiBlue;
            cursor: pointer;
            transition: .2s ease-in background-color;

            &.is-current {
              cursor: initial;
              color: $codGray;
            }
          }
        }

      }
    }
  }
}

@media screen and (max-width: 800px) {
  #navigation {
    height: 70px;

    #fixed-wrapper {
      // justify-content: center;
      padding: 0 32px;
      height: 70px;

      .items {
        display: none;
      }

      .menu-burger {
        display: flex;
      }

      #menu {
        display: flex;

        .wrapper {
          top: 62px;
        }
      }
    }
  }
}
