@import "../../../assets/colors";

#header {
  display: flex;
  flex-direction: column;
  padding: 32px;
  background: $bondiBlue;

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .logo {
      width: 60px;
      height: auto;
    }

    .title {
      margin-left: 24px;
      color: $white;
      font-size: 34px;
    }
  }

  .subtitle {
    margin-top: 8px;
    margin-left: 84px;
    color: $white;
    font-size: 34px;
  }
}

@media screen and (max-width: 800px) {
  #header {
    padding: 24px;
    .wrapper {
      .title {
        font-size: 26px;
      }

      .logo {
        width: 40px;
      }
    }

    .subtitle {
      margin: 8px auto 0;
    }
  }
}
