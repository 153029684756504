@font-face {
  font-family: "BlogScriptW00-Regular";
  src: url("//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.eot");
  src: url("//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.woff") format("woff"), url("//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.svg#BlogScriptW00-Regular") format("svg");
}
* {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

*:not(input):not(textarea) {
  user-select: none;
}

body {
  background-color: #00A0AF;
}

button {
  border-width: 0;
  outline: none;
}

/*# sourceMappingURL=index.css.map */
