.user-form {

  .wrapper {
    display: flex;
    flex-direction: row;
    margin: 16px 0;

    .input-wrapper {
      margin: 0 16px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.email, &.phone {
        flex: 1;
      }

      &.firstName {
        flex: 47;
      }

      &.lastName {
        flex: 27;
      }
    }

    .clean-drop-down {
      margin: 0 16px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.civility {
        flex: 15;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .wrapper {
      flex-direction: column;
    }
  }
}
