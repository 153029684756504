#loginPopup {

  .form-wrapper {
    max-width: 570px;
    min-width: 280px;
    padding: 80px;

    .password {
      margin-top: 12px;
    }

    .button {
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 800px) {
  #loginPopup {

    .wrapper {
      width: 100%;

      .form-wrapper {
        padding: 40px 0;
      }
    }
  }
}
