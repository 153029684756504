@import "../../../assets/colors";

#legalMentions {
  scroll-behavior: smooth;

  .background {
    width: 75%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  >.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 32px 56px 0 56px;

    .side-bar {
      width: 25%;
    }

    .content {
      width: 75%;
      background-color: $white;
      padding: 16px 24px 28px 24px;

      .separator {
        margin-bottom: 24px;
      }

      p {
        color: $codGray;
        font-size: 16px;
        line-height: 24px;
        white-space: pre-wrap;

        &.text {
          margin-top: 12px;
        }

        &.title {
          font-weight: bold;
          font-size: 24px;
          line-height: 32px;
          margin-top: 24px;
        }

        &.subtitle {
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
        }
      }

      .copyright {
        display: flex;
        align-items: center;
        margin-top: 12px;

        .icon {
          height: 24px;
          margin-right: 12px;
        }

        p {
          margin-top: 0;
        }
      }
    }

  }

  @media screen and (max-width: 800px) {

    .navigation {
      padding-left: 32px;
    }

    >.container {
      margin: 24px 24px 0 24px;
      padding: 0;

      .side-bar {
        display: none;
      }

      .content {
        width: 100%;
        padding-top: 0;

        .title {
          font-size: 20px;
        }

        .subtitle {
          font-size: 18px;
        }
      }
    }
  }
}
