@import "../../../../assets/colors";

.user-info {

  .title {
    margin-top: 32px;
    margin-bottom: 24px;
    color: $armadillo;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
  }
}
