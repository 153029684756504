@import "../../../../assets/colors";

#homeSchool {
  padding: 56px 56px 0;

  .background {
    width: 100%;
    position: absolute;
    top: -10%;
    left: -1%;
    z-index: -1;
  }

  .container {
    padding: 55px 100px;
    background: $white;

    .header {
      display: flex;
      flex-direction: column;

      .title {
        margin-bottom: 32px;
        color: $bondiBlue;
        font-size: 34px;
        font-weight: bold;
        line-height: 52px;
        max-width: 85%;
      }

      .text {
        display: flex;
        flex-direction: row;

        .desc {
          flex: 1;
          align-self: center;

          .intro {
            margin-bottom: 30px;
            color: $codGray;
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
          }

          .desc {
            line-height: 24px;
            font-weight: 100;
          }
        }

        img {
          margin-left: 10%;
          align-self: center;
        }
      }
    }

    .inscription {
      display: flex;
      flex-direction: row;
      margin-top: 32px;
      border-top: 1px solid $alto;
      border-bottom: 1px solid $alto;

      .info {
        box-sizing: border-box;
        width: 60%;
        line-height: 24px;
        background-color: $barleyWhite;
        padding: 34px;

        .sub-label {
          margin-top: 20px;
        }
      }

      ul {
        margin-bottom: 16px;

        li {
          margin-left: 20px;
        }
      }

      .side-info {
        width: 40%;
        display: flex;
        flex-direction: column;

        .mini-icons-wrapper {
          flex: 1;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          padding: 24px;
          border-top: 1px solid $mercury;
          border-bottom: 1px solid $mercury;

          .mini-icons-container {
            display: flex;
            flex-direction: column;
            margin-right: 12px;

            p {
              color: $saffron;
              font-size: 18px;
              text-transform: uppercase;
              text-align: center;
              font-family: 'BlogScriptW00-Regular', serif;
            }
          }

          .mini-icon {
            height: 46px;
          }
        }

        .action-wrapper {
          padding: 32px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .button {
            width: calc(100% - 2 * 60px);
            margin: 8px 90px;
            max-width: 190px;
          }
        }
      }
    }

    .contact {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 24px 0;
      background-color: $barleyWhite;
      margin-top: 30px;

      .text {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 24px;

        .desc {
          line-height: 24px;
        }
      }

      .mini-icons-wrapper {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .mini-icons-container {
          display: flex;
          flex-direction: column;
          margin-left: 12px;

          p {
            color: $saffron;
            font-size: 18px;
            text-transform: uppercase;
            text-align: center;
            font-family: 'BlogScriptW00-Regular', serif;
          }
        }

        .mini-icon {
          height: 46px;
        }
      }
    }

    .information {
      margin-top: 40px;

      .desc, .complementary-info {
        line-height: 24px;
      }

      > div {
        padding: 40px 0;
        border-bottom: 1px solid #D1D1D1;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
          border-bottom: none;
        }
      }

      .double-block {
        .wrapper {
          display: flex;
          flex-direction: row;

          .desc {
            flex: 1;
            margin: 0 16px;

            &:first-of-type {
              margin-left: 0;
            }

            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }

    .desc {
      color: #1E1E1E;
      font-size: 16px;
      white-space: pre-line;
    }

    .label {
      margin-bottom: 24px;
      color: #1E1E1E;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
    }

    .sub-label {
      margin-bottom: 20px;
      color: $codGray;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
    }

    .link {
      font-size: 16px;
      color: $cerulean;
      text-decoration: underline;
      cursor: pointer;
    }
  }

}

@media screen and (max-width: 1180px) {
  #homeSchool {
    padding: 0;
    .container {
      .header {
        .text {
            flex-direction: column-reverse;
            align-items: center;
            img {
              margin: 0;
              padding: 24px 0;
            }
        }
        .title {
          margin: 0;
          font-size: 28px;
          line-height: 34px;
          max-width: 100%;
        }
      }

      .inscription {
        flex-direction: column;

        .info {
          width: 100%;
        }

        .side-info {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;



          .mini-icons-wrapper {
            flex-direction: column;
            border: none;
            border-right: 1px solid #E4E4E4;

            .mini-icons-container {
              margin-right: 0;
            }

            .mini-icon {
              height: 46px;
            }
          }
        }
      }

      .contact {
        flex-direction: column;

        .text {
          padding: 0 32px 12px;
          margin-left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  #homeSchool {
    .container {
      padding: 24px;

      .header {
        .title {
          font-size: 20px;
          line-height: 26px;
        }

        .text {

          .desc {
            .intro {
              font-size: 18px;
              line-height: 22px;
              margin-bottom: 24px;
            }

            .desc {
              line-height: 18px;
            }
          }

          img {
            padding: 24px 0;
            max-height: 250px;
          }
        }
      }

      .desc {
        line-height: 18px;
      }

      .label {
        font-size: 20px;
        line-height: 24px;
      }

      .inscription {
        margin-top: 24px;

        .info {
          padding: 24px;
        }

        .side-info {
          flex-direction: column;

          mini-icons-wrapper, actions-wrapper {
            width: 100%;
          }

          .mini-icons-wrapper {
            border: none;
            border-bottom: 1px solid $mercury;
          }
        }
      }

      .contact {
        margin-top: 24px;
        padding: 24px 0;

        .mini-icons-wrapper {
          flex-direction: column;

          .mini-icons-container {
            margin-left: 0;
          }
        }
      }

      .information {
        margin-top: 24px;

        > div {
          padding: 24px 0;
        }

        .double-block {
          .wrapper {
            flex-direction: column;

            .desc {
              margin: 24px 0 0 0;

              &:first-of-type {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
