@import "../../../assets/colors";

.input-wrapper {
  display: flex;
  flex-direction: column;

  > .label {
    color: $lynch;
    font-size: 12px;
    margin-bottom: 4px;
  }

  input {
    height: 48px;
    width: 100%;
    padding: 16px;
    font-size: 16px;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid $white;
    background-color: $athensGray;

    &:focus {
      outline: none;
    }

    &.invalid {
      border-bottom: 2px solid $scarlet;
    }

    &:disabled {
      background-color: transparent;
      border-bottom: 2px solid $black20;
      padding: 0;
    }
  }

  > .error {
    display: none;
    color: $scarlet;
    font-size: 12px;

    &.invalid {
      display: flex;
    }
  }
}

